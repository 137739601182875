import { RouteTitles } from '../design/strings';
import { RoutePaths } from '../design/strings';
const routes = [
	{
		index: true,
		label: RouteTitles.JasonGallagher,
		path: RoutePaths.JasonGallagher,
		on: true,
	},
	{
		label: RouteTitles.Home,
		path: RoutePaths.Home,
		on: true,
	},
	{
		label: RouteTitles.Coding,
		path: RoutePaths.Coding,
		on: true,
	},
	{
		label: RouteTitles.About,
		path: RoutePaths.About,
		on: false,
	},
	{
		label: RouteTitles.Resume,
		path: RoutePaths.Resume,
		on: false,
	},
	{
		label: RouteTitles.Projects,
		path: RoutePaths.Projects,
		on: false,
	},
	{
		label: RouteTitles.Stats,
		path: RoutePaths.Stats,
		on: true,
	},
	{
		label: RouteTitles.Contact,
		path: RoutePaths.Contact,
		on: false,
	},
];

export default routes;
