import { Link } from 'react-router-dom';

import { SideBarStrings } from '../../design/strings';

const { PUBLIC_URL } = process.env;

const SideBar = () => (
	<section id='sidebar'>
		<section id='intro'>
			<Link to='/' className='logo'>
				<img src={`${PUBLIC_URL}/images/jasonn.jpeg`} alt='' />
			</Link>
			<header>
				<h2>{SideBarStrings.Header}</h2>
			</header>
		</section>

		<section className='blurb'>
			<h2>{SideBarStrings.SubHeader}</h2>
			<p>{SideBarStrings.Body}</p>
		</section>
	</section>
);

export default SideBar;
