import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Projects = lazy(() => import('./pages/Projects'));
const Resume = lazy(() => import('./pages/Resume'));
const Coding = lazy(() => import('./pages/Coding'));
const Stats = lazy(() => import('./pages/Stats'));

const Index = lazy(() => import('./pages/Index'));

const App = () => (
	<BrowserRouter basename={PUBLIC_URL}>
		<Suspense fallback={<Main />}>
			<Routes>
				<Route path='/' element={<Index />} />
				<Route path='/about' element={<About />} />
				<Route path='/projects' element={<Projects />} />
				<Route path='/stats' element={<Stats />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/resume' element={<Resume />} />
				<Route path='/coding' element={<Coding />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</Suspense>
	</BrowserRouter>
);

export default App;
