export const WEBSITE_NAME = 'Jason Gallagher';

interface StringByString {
	[key: string]: string;
}

export const RouteTitles: StringByString = {
	JasonGallagher: `${WEBSITE_NAME}`,
	Home: 'Home',
	Coding: 'Coding',
	Skateboarding: 'Skateboarding',
	Resume: 'Resume',
	About: 'About',
	Projects: 'Projects',
	Contact: 'Contact',
	Stats: 'Stats',
	NotFound: '404 Not Found',
};

export const SideBarStrings = {
	Header: `${WEBSITE_NAME}`,
	SubHeader: 'About Me',
	Body: 'Hi my name is Jason Daniel Gallagher. The weather forecast is Sunny, and I hope you have a great day.',
	Description:
		'Ayo, welcome to my page. For anything related to my career checkout ',
};

export const PageHeaders = {
	JasonGallagher: `${WEBSITE_NAME}'s Web Page`,
	Home: `${WEBSITE_NAME}'s Web Page`,
	NotFound: 'Page Not Found',
	Stats: 'Stats',
};

export const PageSubHeaders = {
	JasonGallagher: 'Everything about me in one place.',
	Home: 'Everything about me in one place.',
	Projects: "My life's work.",
};

export const PageBodies = {
	JasonGallagher:
		'Ayo, welcome to my page. For anything related to my career checkout ',
	Home: 'Ayo, welcome to my page. For anything related to my career checkout ',
};

export const PageTitles = {
	JasonGallagher: `${WEBSITE_NAME}`,
	Home: `${WEBSITE_NAME}`,
	Coding: 'Coding',
	Skateboarding: 'Skateboarding',
	Resume: 'Resume',
	About: 'About Me',
	Projects: 'Projects',
	Contact: 'Contact',
	Stats: 'Stats',
	NotFound: '404 Not Found',
};

export const PageDescriptions = {
	JasonGallagher: "Jason Gallagher's personal website.",
	Home: 'Home page for Jason Gallagher.',
	Coding: 'Coding page for Jason Gallagher.',
	Skateboarding: 'Skateboarding page for Jason Gallagher.',
	Resume: 'Resume page for Jason Gallagher.',
	About: 'About page for Jason Gallagher.',
	Projects: 'Projects page for Jason Gallagher.',
	Contact: 'Contact page for Jason Gallagher.',
	Stats: 'Stats page for Jason Gallagher.',
};

export const RoutePaths: StringByString = {
	JasonGallagher: '/',
	Home: '/',
	Coding: '/coding',
	Skateboarding: '/skateboarding',
	Resume: '/resume',
	About: '/about',
	Projects: '/projects',
	Contact: '/contact',
	Stats: '/stats',
};
