import App from './App';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';

// https://react.dev/reference/react/StrictMode
const StrictApp = () => (
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

const rootElement = document.getElementById('root');

// hydrate is required by react-snap.
if (rootElement) {
	if (rootElement.hasChildNodes()) {
		hydrateRoot(rootElement, <StrictApp />);
	} else {
		const root = createRoot(rootElement);
		root.render(<StrictApp />);
	}
}
