import { Link } from 'react-router-dom';

import routes from '../../data/routes';
import Hamburger from './Hamburger';

// Websites Navbar, displays routes defined in 'src/data/routes'
const Navigation = () => (
	<header id='header'>
		<h1 className='index-link'>
			{routes
				.filter((l) => l.index && l.on)
				.map((l) => (
					<Link key={l.label} to={l.path}>
						{l.label}
					</Link>
				))}
		</h1>
		<nav className='links'>
			<ul>
				{routes
					.filter((l) => !l.index && l.on)
					.map((l) => (
						<li key={l.label}>
							<Link to={l.path}>{l.label}</Link>
						</li>
					))}
			</ul>
		</nav>
		<Hamburger />
	</header>
);

export default Navigation;
